import React from 'react';
import { Image } from '@chakra-ui/image';
import { SimpleGrid } from '@chakra-ui/layout';
import SectionWrapper from './SectionWrapper';
import ScrollAnimation from 'react-animate-on-scroll';
import { DevSecThree, DevSecTwo } from '.';
import { Show } from '@chakra-ui/react';
import BoardImg from '../images/board-edit.png';
import LanguageCardsImg from '../images/language-card.png';

export default function DeveloperInfo() {
  return (
    <SectionWrapper paddingBlock={36}>
      <SimpleGrid 
        columns={{ base: 1, lg: 2}} 
        justifyItems={{base:"center", lg:"stretch" }}
        spacing={20}
      >
        <ScrollAnimation
          animateIn="animate__slideInUp"
          animateOnce={true}
          animatePreScroll={false}
        >
          <Show above="sm">
            <Image
              marginInline="auto"
              width='36rem'
              src={BoardImg}
              />
            </Show>
        </ScrollAnimation>
        <DevSecTwo pr={{ base: null, lg:8 }}/>
        <DevSecThree pl={{ base: null, lg:8 }}/>
          <ScrollAnimation
            animateIn="animate__slideInUp"
            animateOnce={true}
            animatePreScroll={false}
          >
            <Show above="sm">
              <Image 
                src={LanguageCardsImg}
                width='36rem' 
                marginStart="auto"
                marginEnd={-8}
              />
            </Show>
          </ScrollAnimation>
      </SimpleGrid>
    </SectionWrapper>
  );
}
