import React from 'react';
import { SimpleGrid, Image } from '@chakra-ui/react';
import fig from '../images/fig.svg';
import thedyrt from '../images/thedyrt.svg';
import walmart from '../images/walmart.svg';
import rigado from '../images/rigado.svg';
import kroger from '../images/kroger.svg';
import audioeye from '../images/audioeye.svg';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionWrapper from './SectionWrapper';

const Brands = () => {
  return (
    <SectionWrapper
    >
      <ScrollAnimation
        animateIn={'animate__fadeInUp'}
        animateOnce={true}
        animatePreScroll={false}
      >
        <SimpleGrid
        marginInline={10}
        columns={[1, null, 2, 3]}
        alignItems="center"
        justifyItems="center"
        gap={20}
        >
          <Image src={fig} height="4rem" alt="Fig Logo" />
          <Image src={walmart} height="3rem" alt="Walmart Logo" />
          <Image src={kroger} height="5rem" alt="Kroger logo" />
          <Image src={rigado} height="3rem" alt="Rigado Logo" />
          <Image src={thedyrt} height="5rem" alt="The Dyrt Logo" />
          <Image src={audioeye} height="3rem" alt="AudioEye logo" />
        </SimpleGrid>
      </ScrollAnimation>
    </SectionWrapper>
  );
};

export default Brands;
