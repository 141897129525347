import React from 'react';
import { Flex, Heading, Text } from '@chakra-ui/layout';
import { PriceCard } from './PriceCard';
import Diamond from '../images/diamond.png';
import Laptop from '../images/laptop.png';
import PriceBgImg from '../images/pricebg.png';
import Setting from '../images/setting.png';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionWrapper from './SectionWrapper';
import FadeInDownArrow from './FadeInDownArrow';

export default function Pricing() {
  const data = [
    {
      id: 1,
      title: 'Code Audit',
      subtitle: `A comprehensive analysis of your source code.`,
      img: Diamond,
      height: '3.938rem',
      width: '4.25rem',
      price: '$795.00/flat',
      sku: 'codefixers-audit',
      includedItems: [
        'Receive a complete report of our audit findings, including potential bugs, security issues, etc',
        'Advice on current tests and recommendations for other feature tests',
        'Consultation on ways code fixers can improve your code base to ship your product faster',
      ],
    },
    {
      id: 2,
      title: 'Monthly',
      subtitle: `No commitment! Cancel or even pause membership as you like.`,
      img: Setting,
      height: '4.063rem',
      width: '4.063rem',
      price: '$4,495.00/month',
      sku: 'codefixers-monthly',
      includedItems: [
        'Unlimited code review requests',
        'Bug tracking and unlimited bug fixes',
        'Test coverage and static analysis tooling',
        'Real time slack collaboration',
        'Pause or cancel anytime',
      ],
    },
    {
      id: 3,
      title: 'Quarterly',
      subtitle: `Save $400 a month in fees! Collected quarterly.`,
      img: Laptop,
      height: '4rem',
      width: '5.125rem',
      price: '$4,095.00/month',
      sku: 'codefixers-quarterly',
      includedItems: [
        'Unlimited code review requests',
        'Bug tracking and unlimited bug fixes',
        'Test coverage and static analysis tooling',
        'Real time slack collaboration',
        'Pause or cancel anytime',
      ],
    },
  ];
  return (
    <SectionWrapper
      paddingInline={[8, null, 16]}
      bg='yellow'
      boxShadow={'lg'}
      position={'relative'}
      backgroundImage={`url(${PriceBgImg})`}
      backgroundSize={'cover'}
      backgroundRepeat={'no-repeat'}
      gap={8}
      pt={{base: 24, md:32, lg: 36}}
      >
      <FadeInDownArrow
        top={{ base:-6, sm: -12, '2xl': -16}}
        />
      <ScrollAnimation animateIn="animate__slideInUp" animateOnce={true}>
        <Heading
          id="price-plans"
          textStyle="xl"
          color="red.solid"
        >
          Pricing
        </Heading>
      </ScrollAnimation>
      <ScrollAnimation animateIn="animate__slideInUp" animateOnce={true}>
        <Text
          variant="subheading"
          textStyle="md"
        >
          Choose the plan that fits your needs
        </Text>
      </ScrollAnimation>
      <Flex
        align={'start'}
        justify={'center'}
        wrap={'wrap'}
        gap={16}
        marginBlock={6}
      >
        {data.map((dt) => (
            <PriceCard key={`price-card-${dt?.id}`} item={dt} />
        ))}
      </Flex>
    </SectionWrapper>
  );
}

