import React from 'react';
import { Button, Heading, Text } from '@chakra-ui/react';
import PlanCards from './PlanCards';
import SectionWrapper from './SectionWrapper';
import ScrollAnimation from 'react-animate-on-scroll';
import scrollTo from 'gatsby-plugin-smoothscroll';

const Plans = () => {
  return (
    <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
      <SectionWrapper id="scope" gap={16} textAlign={{base: "left", md: "center"}}>
        <Heading
          textStyle="lg"
          color="green.solid"
        >
          Technical debt and bug fixes are slowing your team down, but they
          don't have to...
        </Heading>
        <Text>
          Managing software development can be a challenging task, with issues such as poorly tested code, security vulnerabilities, and bugs constantly present. Even the most talented development teams can struggle to create high-quality source code, especially when operating under budget constraints or strict time frames. Every line of code has the potential to increase technical debt, but with the right support, it doesn't have to be that way.
        </Text>
        <PlanCards />
        <Button
          onClick={() => scrollTo('#price-plans')}
        >
          See Plans
        </Button>
      </SectionWrapper>
    </ScrollAnimation>
  );
};

export default Plans;