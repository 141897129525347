import React from 'react';
import { Box, Heading, Text, Image, Center } from '@chakra-ui/react';
import SectionWrapper from './SectionWrapper';
import audioeye from '../images/audioeye-color.svg';
import quotes from '../images/quotes.svg';
import ScrollAnimation from 'react-animate-on-scroll';
import FadeInDownArrow from './FadeInDownArrow';

const TweakDevelopment = () => {
  return (
    <SectionWrapper
      id="benefits"
      bg=" rgba(184, 224, 211, 0.5);"
      position="relative"
      gap={24}
    >
      <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
        <Center flexDir="column" textAlign={{base: "left", md: "center"}} gap={16}>
          <Heading
            textStyle="lg"
            color="brown"
          >
            Eliminate Your Technical Debt and Improve Your Development Process
          </Heading>
          <Text>
            Streamline Your Development Efforts with Expert Support. Managing technical debt, writing unit tests, and implementing analysis tools can be time-consuming and overwhelming. Our years of experience and expertise in software quality improvement can help you tackle these challenges with ease. Let us help you refine your development process and unlock the full potential of your business.
          </Text>
        </Center>
      </ScrollAnimation>
      <FadeInDownArrow
        place="left"
        bottom={[-10, -20, -82]}
      />
      <Box
        width={{ base: '100%', md: 'min(80ch, 100%)', lg:'clamp(80ch, 70%, 120ch)'}}
        boxShadow={'lg'}
        borderRadius={36}
        bg="white"
        paddingInline={[8, null, 16]}
        paddingBlock={2}
      >
        <Image
          src={quotes}
          alt="opening quotation marks"
          position="relative"
          top={[-12, null, -16]}
          height={[16, null, 20]} />
        <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
          <Center flexDir="column" gap={[12, null, 16]}>
            <Text
              textAlign="center"
              textStyle={['sm', null, 'md']}
              maxWidth="48ch"
            >
              We couldn't be happier with the results from Code Fixers. They seamlessly integrated into our team and transformed our frontend development process for the better.
            </Text>
            <Image
              src={audioeye}
              height={[12, null, 16]}
              alt="AudioEye"
            />
          </Center>
        </ScrollAnimation>
        <Image
          src={quotes}
          alt="closing quotation marks"
          position="relative"
          ml="auto"
          bottom={[-12, null, -16]}
          height={[16, null, 20]}
          transform="rotate(180deg)" />
      </Box>
    </SectionWrapper>
  );
};

export default TweakDevelopment;