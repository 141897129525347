import React from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  Show,
  Text,
} from '@chakra-ui/react';

import FadeInCross from './FadeInCross';
import FadeInDownArrow from './FadeInDownArrow';
import SectionWrapper from './SectionWrapper';
import codeImage from '../images/codeImage.png';
import herobgLg from '../images/herobg-lg.gif';
import herobg2xl from '../images/herobg-2xl.gif';
import logo from '../images/logo.svg';
import scrollTo from 'gatsby-plugin-smoothscroll';

const Header = () => {
  return (
    <SectionWrapper
      id="top"
      position='relative'
      backgroundImage={[herobgLg, null, herobg2xl]}
      backgroundRepeat="no-repeat"
      backgroundSize={{base: 'contain', xl: 'cover'}}
      backgroundColor='#FEFDC5'
      pt={8}
      textAlign="center"
    >
      <Flex
        className="animate__animated animate__fadeInUp"
        direction="column"
        alignItems="center"
        justifyContent="space-around"
        gap={12}
      >
        <Image
          src={logo}
          alt="Code Fixers Logo"
          />
        <Heading
          textStyle="2xl"
          color="brown"
          textTransform="uppercase"
          maxWidth="20ch"
        >
          White glove service for your code
        </Heading>
        <Text
          variant="subheading"
          textStyle="md"
        >
          Development subscriptions to debug, test, and keep your code secure
        </Text>
        <Flex
          alignItems='center'
          justifyContent={{ base: 'space-evenly', sm: 'space-around', md:'space-evenly'}}
          direction={{ base: 'column', sm:'row' }}
          gap={{ base: 12, sm: 8, md: 16 }}
          >
          <Button
            onClick={() => scrollTo('#price-plans')}
            >
            See Plans
          </Button>
        </Flex>
        <Show above='md'>
          <Center
            className="animate__animated animate__fadeInUp"
            >
            <Image
              src={codeImage}
              alt="Code example"
              width="min(800px, 80%)"
              />
          </Center>
        </Show>
      </Flex>
      <FadeInCross
        place="left"
        fade="left"
        bottom={{base: 96, md: 72}}
        showAbove="sm"
      />
      <FadeInDownArrow place="right" bottom={-12}/>

    </SectionWrapper>
  );
};

export default Header;
