import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import theme from '../styles/theme';
import '@fontsource/squada-one/400.css';
import '@fontsource/roboto';
import '@fontsource/roboto-condensed';
// import Faqs from './Faqs';
import Brands from '../components/Brands';
import DeveloperInfo from '../components/DeveloperInfo';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Plans from '../components/Plans';
import Pricing from '../components/Pricing';
import { SEO } from '../components';
import TweakDevelopment from '../components/TweakDevelopment';

const IndexPage = () => {
  return (
    <ChakraProvider theme={theme}>
      <SEO />
      <Header />
      <Brands />
      <Plans />
      <TweakDevelopment />
      <DeveloperInfo />
      <Pricing />
      <Footer />
    </ChakraProvider>
  );
};

export default IndexPage;