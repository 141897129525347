import React from 'react';
import { Box, Center, SimpleGrid, Text } from '@chakra-ui/react';
import Lottie from 'lottie-react';
import { planCardData } from '../utils/constant';

const PlanCards = () => {
  return (
    <Center
      flexDir='column'
      gap={12}
    >
      <SimpleGrid
        columns={[ 1, null, 3 ]}
        spacingX="63px"
        spacingY={'5rem'}
        alignItems='baseline'
      >
        { planCardData.map(({ id, animationData, height, text }) => (
          <Center key={`pitch-card-${id}`}
            flexDir='column'
            gap={4}
          >
            <Lottie
              animationData={animationData}
              height={height}
              // width={width}
            />
            <Text
              whiteSpace='pre-line'
              text-align="center"
              width="30ch"
              variant='subheading'
              textAlign="center"
            >
              {text}
            </Text>
          </Center>
          ))
        }
      </SimpleGrid>
    </Center>
  );
};

export default PlanCards;
